import Vue from 'vue'
import Router from 'vue-router'
import SupportersUseList from "@/views/user/SupportersUseList";

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const DashboardUser = () => import('@/views/DashboardUser')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Admin
const ItemList = () => import('@/views/item/ItemList')
const SubscriptionItemList = () => import('@/views/item/SubscriptionItemList')
const OrderList = () => import('@/views/order/OrderList')
const DeliveryList = () => import('@/views/order/DeliveryList')
const RepeatOrderList = () => import('@/views/order/RepeatOrderList')
const ExchangeList = () => import('@/views/order/ExchangeList')
const RefundList = () => import('@/views/order/RefundList')
const AdminUserList = () => import('@/views/user/AdminUserList')
const AdminUserModify = () => import('@/views/user/AdminUserModify')
const UserList = () => import('@/views/user/UserList')
const PurchaseReviewList = () => import('@/views/cs/PurchaseReviewList')
const QNAList = () => import('@/views/cs/QNAList')
const FaqList = () => import('@/views/cs/FaqList')
const InventoryInbound = () => import('@/views/inventory/InventoryInbound')
const InventoryOutbound = () => import('@/views/inventory/InventoryOutbound')
const ItemInventoryList = () => import('@/views/inventory/ItemInventoryList')
const Inventory = () => import('@/views/inventory/Inventory')
const KakaoDeliveryUpload = () => import('@/views/etc/KakaoDeliveryUpload')
const PrintFileUpload = () => import('@/views/etc/PrintFileUpload')
const Automation = () => import('@/views/etc/Automation')
const EventList = () => import('@/views/etc/EventList')
const KakaoRankingView = () => import('@/views/etc/KakaoRankingView')
const KakaoRankingDailyView = () => import('@/views/etc/KakaoRankingDailyView')
const KakaoTodayReview = () => import('@/views/etc/KakaoTodayReview')
const SupportersPresentList = () => import('@/views/etc/SupportersPresentList')
const OliverUTM = () => import('@/views/etc/OliveUTM')

// new 재고 관리
const EndProductList = () => import('@/views/inventory/item/EndProduct')
const SubMaterialList = () => import('@/views/inventory/item/SubMaterial')
const ProductList = () => import('@/views/inventory/item/Product')
const MaterialList = () => import('@/views/inventory/item/Material')
const SupplierList = () => import('@/views/inventory/item/Supplier')
const InventoryManage = () => import('@/views/inventory/manage/InventoryManage')
const WarehouseInventoryList = () => import('@/views/inventory/manage/WarehouseInventory')
const ProductPlanList = () => import('@/views/inventory/manage/ProductPlan')
const PurchaseOrderList = () => import('@/views/inventory/manage/PurchaseOrder')
const InventorySumList = () => import('@/views/inventory/manage/InventorySumList')

// shopEx
const ShopExUserList = () => import('@/views/shopex/ShopExUserList')
const ShopExCompanyList = () => import('@/views/shopex/ShopExCompanyList')
Vue.use(Router)

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({y: 0}),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Dashboard',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'dashboardUser',
          name: 'DashboardUser',
          component: DashboardUser
        },
        {
          path: 'item',
          redirect: '/item/itemList',
          name: '상품아이템',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'itemList',
              name: '상품관리',
              component: ItemList
            },
            {
              path: 'subscriptionItemList',
              name: '구독상품관리',
              component: SubscriptionItemList
            }
          ]
        },
        {
          path: 'order',
          redirect: '/order/orderList',
          name: '주문',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'orderList',
              name: '주문내역',
              component: OrderList
            },
            {
              path: 'deliveryList',
              name: '배송관리',
              component: DeliveryList
            },
            {
              path: 'repeatOrderList',
              name: '구독내역',
              component: RepeatOrderList
            },
            {
              path: 'exchangeList',
              name: '교환관리',
              component: ExchangeList
            },
            {
              path: 'refundList',
              name: '반품관리',
              component: RefundList
            }
          ]
        },
        {
          path: 'user',
          redirect: '/user/userList',
          name: '회원',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'userList',
              name: '회원관리',
              component: UserList
            },
            {
              path: 'aUserList',
              name: '관리자관리',
              component: AdminUserList
            },
            {
              path: 'aUserModify',
              name: '관리자정보수정',
              component: AdminUserModify
            },
            {
              path: 'supportersUserList',
              name: '서포터즈회원관리',
              component: SupportersUseList
            }
          ]
        },
        {
          path: 'cs',
          redirect: '/cs/purchaseReviewList',
          name: 'CS',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'purchaseReviewList',
              name: '구매후기',
              component: PurchaseReviewList
            },
            {
              path: 'qnaList',
              name: '1:1 문의',
              component: QNAList
            },
            {
              path: 'faqList',
              name: 'FAQ 관리',
              component: FaqList
            }
          ]
        },
        {
          path: 'inventoryItem',
          redirect: '/inventoryItem/endProductList',
          name: '재고아이템',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'endProductList',
              name: '배송 상품 관리',
              component: EndProductList
            },
            {
              path: 'subMaterialList',
              name: '부자재 관리',
              component: SubMaterialList
            },
            {
              path: 'productList',
              name: '병입품 관리',
              component: ProductList
            },
            {
              path: 'materialList',
              name: '자재 관리',
              component: MaterialList
            },
            {
              path: 'supplierList',
              name: '거래처 관리',
              component: SupplierList
            }
          ]
        },
        {
          path: 'inventoryManage',
          redirect: '/inventoryManage/warehouseInventoryList',
          name: '재고관리',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'inventoryManage',
              name: '재고 관리',
              component: InventoryManage
            },
            {
              path: 'warehouseInventoryList',
              name: '재고 현황',
              component: WarehouseInventoryList
            },
            {
              path: 'inventorySumList',
              name: '입출고 합계 현황',
              component: InventorySumList
            },
            {
              path: 'productPlanList',
              name: '생산 관리',
              component: ProductPlanList
            },
            {
              path: 'purchaseOrderList',
              name: '발주 관리',
              component: PurchaseOrderList
            }
          ]
        },
        {
          path: 'inventory',
          redirect: '/inventory/inventoryInbound',
          name: '재고',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'inventoryInbound',
              name: '입고 관리',
              component: InventoryInbound
            },
            {
              path: 'inventoryOutbound',
              name: '출고 관리',
              component: InventoryOutbound
            },
            {
              path: 'inventoryItemList',   // itemInventoryList 로 했을때 /item 도 같이 dropdown 되는 이슈로...
              name: '아이템 자재관리',
              component: ItemInventoryList
            },
            {
              path: 'inventoryList',
              name: '자재 관리',
              component: Inventory
            }
          ]
        },
        {
          path: 'etc',
          redirect: '/etc/kakaoDeliveryExcelUpload',
          name: '기타작업',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'kakaoDeliveryExcelUpload',
              name: '카카오배송업로드',
              component: KakaoDeliveryUpload
            },
            {
              path: 'printFileUpload',
              name: '인쇄물파일업로드',
              component: PrintFileUpload
            },
            {
              path: 'automation',
              name: '배송자동화',
              component: Automation
            },
            {
              path: 'eventList',
              name: '이벤트',
              component: EventList
            },
            {
              path: 'kakaoRankingView',
              name: '카카오실시간랭킹',
              component: KakaoRankingView
            },
            {
              path: 'KakaoRankingDailyView',
              name: '카카오랭킹일별조회',
              component: KakaoRankingDailyView
            },
            {
              path: 'kakaoTodayReview',
              name: '오늘자리뷰관리',
              component: KakaoTodayReview
            },
            {
              path: 'supportersPresentList',
              name: '서포터스선물신청',
              component: SupportersPresentList
            },
            {
              path: 'oliveUTM',
              name: '올리브영 UTM',
              component: OliverUTM
            }
          ]
        },
        {
          path: 'shopex',
          redirect: '/shopex/shopExUserList',
          name: '기타작업',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'shopExUserList',
              name: 'ShopEx 회원관리',
              component: ShopExUserList
            },
            {
              path: 'shopExCompanyList',
              name: 'ShopEx 회원사관리',
              component: ShopExCompanyList
            }
          ]
        }
      ]
    },
    {
      path: '*',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    }
  ]
})
