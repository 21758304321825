import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  status: '',
  token: localStorage.getItem('token') || '',
  refresh: false,
  user: [],
  oauth2User: {
    properties: {
      nickname: null
    },
    kakao_account: {
      email: null,
      age_range: null,
      birthday: null,
      gender: null
    }
  }
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  },
  auth_request(state) {
    state.status = 'loading'
    state.refresh = true
  },
  auth_success(state, data) {
    state.status = 'success'
    state.token = data.token
    state.user = data.user
    state.refresh = false
  },
  auth_error(state) {
    state.status = 'error'
    state.refresh = false
  },
  logout(state) {
    state.status = ''
    state.token = ''
    state.refresh = false
    state.user = {}
  },
  oauth2User(state, data) {
    state.oauth2User = data
  }
}

const actions = {
  login ({ commit }, detail) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios.post('/admin/login', detail).then(({ data }) => {
        localStorage.setItem('token', data.token)
        localStorage.setItem('refresh', data.refreshToken)
        axios.defaults.headers.common.Authorization = data.token
        commit('auth_success', data)
        resolve(data)
      }).catch((error) => {
        commit('auth_error')
        localStorage.removeItem('token')
        localStorage.removeItem('refresh')
        reject(error.response.data.message)
      })
    })
  },
  oauth2Login ({ commit }, accessToken) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios.post('/api/oauth2Login', { accessToken: accessToken, provider: 'kakao' }).then(({ data }) => {
        const isUser = data.isUser
        if (isUser === 'yes') {
          localStorage.setItem('token', data.token)
          localStorage.setItem('refresh', data.refreshToken)
          axios.defaults.headers.common.Authorization = data.token
          commit('auth_success', data)
        } else {
          alert(JSON.stringify(data.kakaoProfile))
          commit('oauth2User', data.kakaoProfile)
        }
        resolve(data)
      }).catch((error) => {
        commit('auth_error')
        localStorage.removeItem('token')
        localStorage.removeItem('refresh')
        reject(new Error(error.response.data.message))
      })
    })
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      commit('logout')
      localStorage.removeItem('token')
      localStorage.removeItem('refresh')
      delete axios.defaults.headers.common.Authorization
      resolve()
    })
  }
}

const getters = {
    isLogin: state => !!state.token,
    isRefresh: state => state.refresh,
    user: state => state.user,
    oauth2User: function (state) {
    return state.oauth2User
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})
