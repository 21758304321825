export default {
  install(Vue) {
    Vue.prototype.$getToday = function() {
      const date = new Date()
      const year = date.getFullYear()
      const month = ("0" + (1 + date.getMonth())).slice(-2)
      const day = ("0" + date.getDate()).slice(-2)

      return year + "-" + month + "-" + day
    }

    Vue.prototype.$getOneWeekAgo = function() {
      const now = new Date()
      const date = new Date(now.setDate(now.getDate() - 7))
      const year = date.getFullYear()
      const month = ("0" + (1 + date.getMonth())).slice(-2)
      const day = ("0" + date.getDate()).slice(-2)

      return year + "-" + month + "-" + day
    }

    Vue.prototype.$getOneMonthAgo = function() {
      const now = new Date()
      const date = new Date(now.setMonth(now.getMonth() - 1))
      const year = date.getFullYear()
      const month = ("0" + (1 + date.getMonth())).slice(-2)
      const day = ("0" + date.getDate()).slice(-2)

      return year + "-" + month + "-" + day
    }

    Vue.prototype.$getOneYearAgo = function() {
      const now = new Date()
      const date = new Date(now.setFullYear(now.getFullYear() - 1))
      const year = date.getFullYear()
      const month = ("0" + (1 + date.getMonth())).slice(-2)
      const day = ("0" + date.getDate()).slice(-2)

      return year + "-" + month + "-" + day
    }
  }
}
