<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="d-flex flex-row justify-content-between pb-4">
          <span class="h2">서포터즈 회원 관리</span>
        </div>
        <div class="form-inline pb-3">
          <div class="form-group mr-3">
            <CInput
              label="회원명"
              type="text"
              v-model="search.userInfo"
              @keyup.enter="init()"
              horizontal
            />
          </div>
          <div class="form-group mr-3">
            <CSelect
              label="인증여부"
              :value.sync="search.supportersAuthYN"
              :options="[{value: null, label: '전체'},{value: 'Y', label: 'Y'},{value: 'N', label: 'N'}]"
              horizontal
            />
          </div>
          <div class="form-group mr-2">
            <CButton
              color="primary"
              square
              @click="init()"
            >검색
            </CButton>
          </div>
          <div class="form-group">
            <CButton
              color="primary"
              variant="outline"
              square
              @click="searchInit()"
            >초기화
            </CButton>
          </div>
        </div>
        <div class="float-right pb-3">
          <span>총 {{ totalCount }}건 &nbsp;&nbsp;</span>
          <select v-model="size">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
        </div>
        <CDataTable
          :items="userList"
          :fields="fields"
          outlined
          :loading="loading"
          hover
        >
          <template #auth="{item}">
            <td class="py-2">
              <CButton
                v-if="item.supportersAuthYN == 'N'"
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="approveSupporters(item)"
              >
                승인하기
              </CButton>
              <CButton
                v-else
                color="danger"
                variant="outline"
                square
                size="sm"
                @click="disapproveSupporters(item)"
              >
                승인취소
              </CButton>
            </td>
          </template>
          <template #show_details="{item}">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="showPointModal(item)"
              >
                내역보기
              </CButton>
            </td>
          </template>
          <template #details="{item}">
            <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
              <div class="p-3" style="background:#f3f3f3">
                <CRow>
                  <CCol sm="12" md="6" lg="4">
                    <CInput
                      v-model="item.username"
                      label="이름"
                    />
                  </CCol>
                  <CCol sm="12" md="6" lg="4">
                    <CInput
                      v-model="item.mobilePhone"
                      label="휴대폰"
                    />
                  </CCol>
                  <CCol sm="12" md="6" lg="4">
                    <CInput
                      v-model="item.birthday"
                      label="생년월일"
                    />
                  </CCol>
                  <CCol sm="12" md="6" lg="4">
                    <CSelect v-model="item.sex" :value.sync="item.sex"
                             :options="[{value:'N', label:'데이터없음'},{value:'M', label:'남성'},{value:'F', label:'여성'}]"
                             label="성별"
                    />
                  </CCol>
                  <CCol sm="12" md="6" lg="4">
                    <CSelect v-model="item.agreeMarketing" :value.sync="item.agreeMarketing"
                             :options="[{value:'Y', label:'동의'},{value:'N', label:'미동의'}]"
                             label="마케팅동의"
                    />
                  </CCol>
                </CRow>
                <hr/>
                <div class="form-row">
                  <CButton
                    color="success"
                    variant=""
                    @click="modifyUser(item)"
                  > 정보수정
                  </CButton>
                </div>
              </div>
            </CCollapse>
          </template>
        </CDataTable>
        <CPagination
          :pages="totalPages"
          :active-page.sync="page"
          align="center"
        />
      </CCardBody>
    </CCard>
    <SupportersPointListModal v-if="pointModal" :point-modal.sync="pointModal" :user-id="userId"></SupportersPointListModal>
  </div>
</template>

<script>
import axios from 'axios'
import SupportersPointListModal from '@/components/user/supportersPointListModal'

const fields = [
  {key: 'userId', label: '회원번호'},
  {key: 'username', label: '이름'},
  {key: 'emailAddress', label: '이메일'},
  {key: 'mobilePhone', label: '핸드폰'},
  {key: 'createDate', label: '가입일'},
  {key: 'supportersAuthYN', label: '승인여부'},
  {key: 'auth', label: '승인하기'},
  {key: 'show_details', label: '포인트내역', _style: 'width:10%', sorter: false, filter: false}
]

export default {
  name: 'SupportersUseList',
  components:{
    SupportersPointListModal
  },
  data() {
    return {
      userList: [],
      fields,
      loading: false,
      page: 1,
      size: 10,
      totalPages: 0,
      totalCount: 0,
      collapseDuration: 0,
      search: {
        from: this.$route.query.from || this.$getOneMonthAgo(),
        to: this.$route.query.to || this.$getToday(),
        userInfo: null,
        supportersAuthYN: null
      },
      userId: null,
      pointModal: false
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      axios.get('/admin/user/userList',
        {
          params: {
            page: this.page - 1,
            size: this.size,
            sort: '',
            appUserIdYN: 'Y',
            userInfo: this.search.userInfo,
            supportersAuthYN: this.search.supportersAuthYN
          }
        }).then(({data}) => {
        console.log(data.result)
        this.userList = data.result.content
        this.totalPages = data.result.totalPages
        this.totalCount = data.result.totalElements
      }).catch((error) => {
        console.log(error)
      })
    },
    searchInit() {
      this.search.userInfo = null
      this.search.from = this.$getOneMonthAgo()
      this.search.to = this.$getToday()
      this.search.supportersAuthYN = null

      this.page = 1

      this.init()
    },
    toggleDetails(item) {
      this.$set(item, '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => {
        this.collapseDuration = 0
      })
    },
    showPointModal(item) {
      this.userId = item.userId
      this.pointModal = true
    },
    modifyUser(item) {
      axios.post('/admin/user/modify', item).then(({data}) => {
        console.log(data.result)
        alert('수정되었습니다.')
      }).catch((error) => {
        console.log(error)
      })
    },
    approveSupporters(item) {
      axios.get('/admin/user/approveSupporters/'+item.userId).then(({}) => {
        alert('승인되었습니다.')
        this.init()
      }).catch((error) => {
        console.log(error)
      })
    },
    disapproveSupporters(item) {
      axios.get('/admin/user/disapproveSupporters/'+item.userId).then(({}) => {
        alert('승인취소되었습니다.')
        this.init()
      }).catch((error) => {
        console.log(error)
      })
    }
  },
  watch: {
    page: function () {
      this.init()
    }
    ,
    size: function () {
      this.init()
    }
  }
}
</script>
