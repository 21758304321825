<template>
  <div>
    <CModal
      size="lg"
      color="black"
      :show.sync="pointModal"
      :close-on-backdrop="false"
    >
      <CCard>
        <CCardBody>
          <div class="d-flex flex-row justify-content-between pb-2">
            <span class="h2">총 포인트 : {{totalPoint | makeComma}}</span>
          </div>
          <CDataTable
            :items="pointList"
            :fields="fields"
            :loading="loading"
            hover
          >
            <template #pointType="{item}">
              <td class="align-middle text-center" v-if="item.pointType == 'acc'">
               적립
              </td>
              <td class="align-middle text-center" v-else>
                사용
              </td>
            </template>
            <template #point="{item}">
              <td class="align-middle">
                {{ item.point | makeComma }}
              </td>
            </template>
          </CDataTable>
          <CPagination
            :pages="totalPages"
            :active-page.sync="page"
            align="center"
          />
        </CCardBody>
      </CCard>
      <template #header>
        <h5 class="modal-title">포인트 내역</h5>
        <CButtonClose @click="cancelModal" buttonClasses="text-primary close"></CButtonClose>
      </template>
      <template #footer>
        <CButton @click="cancelModal" color="danger">창 닫기</CButton>
        <!--      <CButton @click="submitForm" color="success">수정</CButton>-->
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from 'axios'

const fields = [
  {key: 'supportersPointId', label: '포인트번호', _style: 'width:15%'},
  {key: 'pointType', label: '구분', _style: 'width:15%'},
  {key: 'point', label: '포인트'},
  {key: 'createDate', label: '일자', _style: 'width:40%'}
]

export default {
  name: 'supportersPointListModal',
  props:{
    pointModal : Boolean,
    userId: Number
  },
  data() {
    return {
      pointList: [],
      fields,
      loading: false,
      page: 1,
      size: 10,
      totalPages: 0,
      totalCount: 0,
      totalPoint: 0
    }
  },
  created() {
    this.init()
    this.getTotalPoint()
  },
  methods: {
    init() {
      axios.get('/admin/user/getSupportersPointList',
        {
          params: {
            page: this.page - 1,
            size: this.size,
            userId: this.userId
          }
        }).then(({data}) => {
        console.log(data.result)
        this.pointList = data.result.content
        this.totalPages = data.result.totalPages
        this.totalCount = data.result.totalElements
      }).catch((error) => {
        console.log(error)
      })
    },
    getTotalPoint() {
      axios.get('/admin/user/getSupportersPoint/'+this.userId).then(({data}) => {
        console.log(data.result)
        this.totalPoint = data.result

      }).catch((error) => {
        console.log(error)
      })
    },
    cancelModal() {
      this.$emit('update:pointModal', false)
    }
  },
  watch: {
    page: function () {
      this.init()
    },
    size: function () {
      this.init()
    }
  }
}
</script>
