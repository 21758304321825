import 'core-js/stable'
import Vue from 'vue'
//import CoreuiVuePro from '@coreui/vue-pro'
import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js'
import App from './App'
import router from './router/index'
import {iconsSet as icons} from './assets/icons/icons.js'
import store from './store'
import i18n from './i18n.js'
import axios from 'axios'
import 'regenerator-runtime/runtime'
import CKEditor from 'ckeditor4-vue'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import Multiselect from "vue-multiselect"
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import heatmap from 'highcharts/modules/heatmap'
import commonFN from './assets/common_function'
import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

heatmap(Highcharts);

Vue.use(HighchartsVue, {
  highcharts: Highcharts
})

Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
})

Vue.use(CoreuiVuePro)
Vue.use(CKEditor)
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)
Vue.component('multiselect', Multiselect)

Vue.use(commonFN)

Vue.use(VueGoodTablePlugin)

Vue.prototype.$log = console.log.bind(console)

axios.defaults.baseURL = process.env.VUE_APP_BACKEND
axios.defaults.headers.common.Accept = 'application/json'

Vue.prototype.$http = axios
const token = localStorage.getItem('token')
if (token) {
  // Vue.prototype.$http.defaults.headers.common.Authorization = token
  axios.defaults.headers.common.Authorization = token
}

const clientDomain = location.hostname
/*if(clientDomain === 'localhost') {
  axios.defaults.headers.common.Company = 1
} else if(clientDomain === 'admin.monscent.com') {
  axios.defaults.headers.common.Company = 1
} else if(clientDomain === 'admin.boho.com') {
  axios.defaults.headers.common.Company = 2
}*/

switch (clientDomain) {
  case 'admin.monscent.com':
    axios.defaults.headers.common.Company = 1
    break
  case 'bohoadmin.shopex.co.kr':
    axios.defaults.headers.common.Company = 2
    break
  default:
    axios.defaults.headers.common.Company = 1
    break
}

/*axios.interceptors.request.use(
  async function (config) {
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)*/

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  failedQueue = []
}

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const originalRequest = error.config
    if (error.response.status === 403 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({resolve, reject})
        }).then(token => {
          originalRequest.headers.Authorization = token
          return axios(originalRequest)
        }).catch(err => {
          return Promise.reject(err)
        })
      }

      originalRequest._retry = true
      isRefreshing = true

      const refreshToken = localStorage.getItem('refresh')
      return new Promise(function (resolve, reject) {
        axios.post('/admin/refresh', {refreshToken: refreshToken}).then(({data}) => {
          localStorage.setItem('token', data.token)
          // localStorage.setItem('refresh', data.refreshToken)
          axios.defaults.headers.common.Authorization = data.token
          originalRequest.headers.Authorization = data.token
          processQueue(null, data.token)
          resolve(axios(originalRequest))
        }).catch((err) => {
          store.dispatch('logout')
          router.push('/login')
          processQueue(err, null);
          reject(err)
        }).finally(() => {
          isRefreshing = false
        })
      })
    }
    return Promise.reject(error);
  }
)

Vue.filter('makeComma', val => {
  return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
})

new Vue({
  el: '#app',
  router,
  store,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  i18n,
  template: '<App/>',
  components: {
    App
  }
})
