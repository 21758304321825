// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export const logo = ['186 50', `
<g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
fill="fill:currentColor;" stroke="none">
<path d="M75 463 c-31 -8 -39 -20 -17 -27 18 -6 21 -19 27 -141 9 -165 2 -223
-28 -235 -16 -6 2 -9 58 -9 71 -1 77 1 55 12 l-25 13 -3 197 c-2 197 -2 197
-25 196 -12 -1 -31 -3 -42 -6z"/>
<path d="M1275 463 c-47 -10 -50 -14 -25 -26 25 -13 25 -13 28 -182 3 -163 2
-171 -19 -188 -21 -17 -19 -17 52 -16 61 0 70 2 52 11 -23 11 -23 14 -23 210
l0 198 -22 -1 c-13 -1 -32 -3 -43 -6z"/>
<path d="M1445 463 c-32 -8 -39 -20 -16 -28 20 -6 21 -12 21 -186 0 -177 0
-180 -22 -189 -17 -6 -3 -9 52 -9 70 -1 73 0 52 15 -21 15 -22 20 -22 210 l0
194 -22 -1 c-13 -1 -32 -3 -43 -6z"/>
<path d="M326 315 c-50 -17 -72 -39 -91 -90 -21 -54 -8 -109 35 -150 28 -27
38 -30 95 -30 53 0 68 4 89 24 35 33 22 47 -16 16 -16 -14 -39 -25 -52 -25
-47 0 -96 60 -96 119 l0 30 98 3 c97 3 97 3 94 28 -6 59 -88 98 -156 75z m82
-27 c7 -7 12 -22 12 -35 0 -21 -4 -23 -60 -23 -65 0 -72 8 -44 48 17 25 71 31
92 10z"/>
<path d="M1694 314 c-49 -17 -91 -63 -100 -110 -10 -53 21 -121 66 -146 48
-26 130 -21 165 12 34 32 20 46 -17 15 -38 -32 -70 -32 -109 3 -24 21 -32 38
-36 74 l-6 47 99 3 99 3 -3 25 c-9 61 -88 98 -158 74z m89 -35 c21 -33 1 -49
-58 -49 -60 0 -72 15 -38 52 21 23 81 21 96 -3z"/>
<path d="M773 300 c-90 -54 -96 -188 -11 -240 34 -21 98 -27 98 -10 0 6 -7 10
-16 10 -8 0 -29 13 -45 29 -25 26 -29 37 -29 86 0 59 19 109 45 119 31 12 60
6 73 -14 13 -22 37 -26 47 -9 4 5 -1 19 -11 30 -24 26 -107 26 -151 -1z"/>
<path d="M1073 314 c4 -4 17 -12 29 -18 15 -7 23 -21 23 -36 0 -23 -4 -25 -52
-28 -91 -5 -60 -23 35 -20 86 3 87 3 84 28 -4 36 -56 80 -94 80 -17 0 -28 -3
-25 -6z"/>
<path d="M972 139 l-21 -34 29 -29 c39 -39 119 -47 168 -17 17 12 32 28 32 37
0 12 -7 10 -31 -10 -17 -14 -41 -26 -54 -26 -32 0 -76 40 -90 80 l-11 33 -22
-34z"/>
</g>
`]

